@use '../custom-variables/mixins';

.header-section {
  .main-header-section {
    @include mixins.header-bg-1('/img/bg/grids/grid-1.svg');
    background-position: center;

    padding-bottom: 4.5rem;
  }
}
